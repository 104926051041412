<template>
  <div>
    <UserInfo />
    <div class="container-crearProyecto">
      <h1 class="title-consultorioMovil">Nuevo proyecto consultorio móvil</h1>
      <h3>Información general</h3>
      <el-form
        :model="FormProject"
        :rules="rules"
        status-icon
        ref="FormProject"
        label-width="120px"
        class="demo-FormProject"
        label-position="top"
      >
      <el-row :gutter="20" class="row-consultorioMovil">
        <el-col :span="4">
          <el-form-item label="Estado" prop="estado">
            <el-select
              @change="getMunicipios"
              filterable
              v-model="FormProject.estado"
              clearable
              placeholder="Seleccionar">
            <el-option
              v-for="item in estados"
              :key="item.name"
              :label="`${item.name} (${item.id})`"
              :value="item.name"  />
          </el-select>
          </el-form-item>
          <el-form-item label="Municipio" prop="municipio">
            <el-select
              :disabled="!FormProject.estado"
              @change="getDelegaciones"
              filterable
              v-model="FormProject.municipio"
              clearable
              placeholder="Seleccionar">
            <el-option
              v-for="item in municipios"
              :key="item.id"
              :label="item.name"
              :value="item.id"  />
          </el-select>
          </el-form-item>
          <el-form-item label="Delegación" prop="delegacion">
            <el-select
              filterable
              v-model="FormProject.delegacion"
              :disabled="!FormProject.municipio"
              clearable
              placeholder="Seleccionar">
            <el-option
              v-for="item in delegaciones"
              :key="item.id"
              :label="item.name"
              :value="item.id"  />
            </el-select>
          </el-form-item>
          <el-form-item label="Colonia" prop="colonia">
            <el-select
              @change="getColoniaInfo"
              filterable
              v-model="FormProject.colonia"
              clearable
              placeholder="Seleccionar">
            <el-option
              v-for="item in colonias"
              :key="item.id"
              :label="item.name"
              :value="item.id"  />
            </el-select>
          </el-form-item>
          <el-form-item label="Dirección" prop="direccion">
            <el-input v-model="FormProject.direccion" />
          </el-form-item>
          <el-form-item label="Código postal" prop="cp">
            <!-- <el-select
              filterable
              v-model="FormProject.cp"
              clearable
              placeholder="Seleccionar">
            <el-option
              v-for="item in codigosPostales"
              :key="item.id"
              :label="item.name"
              :value="item.id"  />
            </el-select> -->
            <el-input v-model="FormProject.cp" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Hora" prop="hora">
            <el-time-select
              v-model="FormProject.hora"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '18:30'
              }"
              placeholder="Seleccionar una hora"/>
          </el-form-item>
          <el-form-item label="Alianza" prop="alianza">
            <el-select
              filterable
              v-model="FormProject.alianza"
              clearable
              placeholder="Seleccionar">
            <el-option
              v-for="item in alianzas"
              :key="item.id"
              :label="item.name"
              :value="item.id"  />
            </el-select>
          </el-form-item>
          <el-form-item label="Contacto" prop="contacto">
            <el-input v-model="FormProject.contacto" />
          </el-form-item>
          <el-form-item label="Observación" prop="observacion">
            <el-input v-model="FormProject.observacion" />
          </el-form-item>
            <el-button class="button-agendar" size="medium" @click="submitForm('FormProject')">
              Agendar
          </el-button>
          </el-col>
        <el-col :span="12" class="pickerDate">
          <span>SELECCIONAR FECHA</span>
          <el-form-item label="fecha" prop="fecha">
            <datepicker
              v-model="selectedDate"
              :language="languages['es']"
              :inline="true"
              @changedMonth="changedMonth"
            />
          </el-form-item>
        </el-col>
      </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import UserInfo from '../../../components/userInfo';
import MunicipioApi from '../../../API/comunidad/municipio';
import AlianzasAPI from '../../../API/comunidad/alianzas';
import DelegacionAPI from '../../../API/comunidad/delegacion';
import ColoniaAPI from '../../../API/comunidad/colonias';
import ProyectoAPI from '../../../API/comunidad/proyectos';
import { estadosAPI, municipiosAPI } from '../../../utils/estados';

const moment = require('moment');

export default {
  data() {
    return {
      selectedDate: null,
      languages: lang,
      estados: [],
      municipios: [],
      delegaciones: [],
      colonias: [],
      alianzas: [],
      FormProject: {
        name: 'consultorioMovil',
        estado: '',
        municipio: '',
        delegacion: '',
        colonia: '',
        cp: '',
        direccion: '',
        hora: '',
        fecha: '',
        alianza: '',
        contacto: '',
        observacion: '',
      },
      rules: {
        municipio: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        estado: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        delegacion: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        colonia: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        direccion: [{ required: true, message: '¡Campo requerido!', trigger: 'blur' }],
        cp: [{ required: true, message: '¡Campo requerido!', trigger: 'blur' }],
        hora: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        alianza: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        fecha: [{ required: true, message: 'Seleccione una fecha válida', trigger: 'blur' }],
      },
    };
  },
  components: {
    UserInfo,
    Datepicker,
  },
  methods: {
    submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.createProyect();
        } else {
          return false;
        }
      });
    },
    async createProyect() {
      try {
        const request = await ProyectoAPI.postcomunidadProyecto(this.FormProject);
        if (request) {
          this.$notify({
            type: 'success',
            message: '¡Proyecto agregado correctamente!',
          });
        }
      } catch (error) {
        this.$notifyErro(error);
      }
    },
    async changedMonth(args) {
      const FORMAT = 'YYYY-MM-DD';
      let date = null;
      if (args.timestamp) {
        date = moment(new Date(args.timestamp)).format(FORMAT);
      } else {
        date = moment(args).format(FORMAT);
      }
      await this.hightlights(date);
    },
    async getColoniaInfo() {
      try {
        const request = await ColoniaAPI.getcoloniaCPId(this.FormProject.colonia);
        if (request) this.FormProject.cp = request[0]?.codigoPostal?.zipCode;
      } catch (error) {
        // console.log(error);
      }
    },
    async getColonia() {
      this.colonias = [];
      this.FormProject.colonia = null;
      const { municipio } = this.FormProject;
      const colonias = await ColoniaAPI.getcoloniaMunicipio(municipio);
      if (colonias) this.colonias.push(...colonias);
    },
    async getMunicipios() {
      this.municipios = [];
      this.FormProject.municipio = null;
      const { estado } = this.FormProject;
      const municipios = await municipiosAPI[0][estado];
      municipios.forEach((municipio) => {
        this.municipios.push({
          id: municipio,
          name: municipio,
        });
      });
    },
    async getDelegaciones() {
      this.delegaciones = [];
      this.FormProject.delegacion = null;
      const { municipio } = this.FormProject;
      const delegaciones = await DelegacionAPI.getdelegacion(municipio);
      if (delegaciones) this.delegaciones.push(...delegaciones);
      this.getColonia();
    },
  },
  async created() {
    estadosAPI.forEach((estado) => {
      this.estados.push({
        id: estado.clave,
        name: estado.nombre,
      });
    });

    const alianzas = await AlianzasAPI.getAlianzas();
    if (alianzas) this.alianzas.push(...alianzas);
  },
  watch: {
    async selectedDate(val) {
      if (val) {
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.FormProject.fecha = date;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-consultorioMovil {
  margin: 0px;
  color: #8F70C7;
}
h3 {
  margin: 0px;
  color: #3e3e3e;
}
.button-agendar {
  background-color: #8F70C7;
  // border: none;
  color: #fff;
}
.row-consultorioMovil {
  margin-top: 15px;
}
.pickerDate {
  margin-top: 0px;
  span {
    display: inline-block;
    width: 300px;
    text-align: center;
    font-weight: bold;
    color: #8F70C7;
    font-size: 18px;
    padding: 10px;
  }
}
</style>

<style lang="scss">
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-row {
    width: 200px;
  }
}
.container-crearProyecto {
  padding: 20px;
}
.el-form--label-top .el-form-item__label {
  padding: 0px;
}/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(157, 108, 229);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(123, 59, 218, 0.855);
  border-radius: 12px;
}
.vdp-datepicker__calendar {
  padding: 5px;
}
.vdp-datepicker__calendar .cell.selected {
  background: #6a41bdd7 !important;
  color: #fff;
}
.vdp-datepicker__calendar .cell.highlighted {
    background: #c4a7ff !important;
    color: #fff;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled):hover {
  background: rgba(104, 85, 247, 0.25) !important;
  color: #fff;
  border: none;
}
.vdp-datepicker__calendar {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 10px 2px rrgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 10px 2px rrgba(0, 0, 0, 0.19);
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: none !important;
}
.vdp-datepicker__calendar .cell.blank:hover {
  background: #ffffff !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border-radius: 50%;
}
.vdp-datepicker__calendar .cell.selected:hover {
  background: #006eff;
}
.vdp-datepicker__calendar .cell:hover {
  background: #006eff;
}
</style>
